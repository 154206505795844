<template>
    <div ref="appContainer" @dragenter="setHightlights" @dragover="setHightlights" 
        @dragend="unsetHighlights" @drop="unsetHighlights">
    <transition name="fade">
        <div class="spinnerContainer" v-show="showSpinner">
            <div class="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </transition>
    <template v-if="showApp">
        <div class="appMode" v-if="appMode != 'production'">Modalità: {{appMode}}</div>
        <router-view/>
    </template>
    <op-modal-container></op-modal-container>
</div>
</template>

<script lang="ts">
import store from '@/store';
import { ModalServices } from '@/services/ModalServices'
import { LoginClient } from './services/Services';
import { Options, Vue } from 'vue-class-component';
import { mode } from './config';
import { CommonServices } from './services/CommonServices';

@Options({})
export default class App extends Vue {
    
    get showSpinner(){
        return store.state.showSpinner > 0;
    }
    showApp: boolean = false;
    appMode: string = "";
    
    created(){
        this.appMode = mode;
        
        ModalServices.setOpModal(this.$opModal);
    
        LoginClient.refreshLogin()
        .then(x => {
            CommonServices.setAuthToken(x);
        })
        .catch(err => {
            this.$router.replace("/");
        })
        .finally(() => {
            this.showApp = true;
        })
    }
    setHightlights(event){
        event.preventDefault();
        (this.$refs.appContainer as HTMLElement).classList.add('highlightDrags');
    }
    unsetHighlights(event){
        event.preventDefault();
        (this.$refs.appContainer as HTMLElement).classList.remove('highlightDrags');
    }
}

</script>
