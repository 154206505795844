import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "spinnerContainer" }
const _hoisted_2 = {
  key: 0,
  class: "appMode"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_op_modal_container = _resolveComponent("op-modal-container")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "appContainer",
    onDragenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setHightlights && _ctx.setHightlights(...args))),
    onDragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setHightlights && _ctx.setHightlights(...args))),
    onDragend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.unsetHighlights && _ctx.unsetHighlights(...args))),
    onDrop: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.unsetHighlights && _ctx.unsetHighlights(...args)))
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "spinner" }, [
            _createElementVNode("div"),
            _createElementVNode("div"),
            _createElementVNode("div"),
            _createElementVNode("div")
          ], -1)
        ]), 512), [
          [_vShow, _ctx.showSpinner]
        ])
      ]),
      _: 1
    }),
    (_ctx.showApp)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.appMode != 'production')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Modalità: " + _toDisplayString(_ctx.appMode), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_op_modal_container)
  ], 544))
}