import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { createStore, StoreOptions } from 'vuex';
import { StorageServices } from '@/services/StorageServices';
import { RouteLocationNormalizedLoadedGeneric } from 'vue-router';

let state = {
    showSpinner: 0,
    pageTitle: "",
    routeBuster: false,
    beforeNavCbs: <((to: RouteLocationNormalizedLoadedGeneric, from: RouteLocationNormalizedLoadedGeneric) => void)[]>[],
    afterNavCbs: <((to: RouteLocationNormalizedLoadedGeneric, from: RouteLocationNormalizedLoadedGeneric) => void)[]>[],
    formErrors: <{ [key: string]: boolean }>{},
    copyToClipboard: copyToClipboard,
}


let vuestore: StoreOptions<typeof state> = {
    state: state,
	mutations: {
		showSpinner(state){
            state.showSpinner++;
		},
		hideSpinner(state){
            state.showSpinner--;
            if(state.showSpinner < 0){
                state.showSpinner = 0;
            }
        }
	},
}

let store = createStore(vuestore);

export default store

function copyToClipboard(text, clickEv: MouseEvent = null) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    
    if(clickEv){
        var popup = document.createElement("div");
        popup.style.position = "fixed";
        popup.style.left = clickEv.clientX + "px";
        popup.style.top = clickEv.clientY + "px";
        popup.style.backgroundColor = "white";
        popup.style.border = "1px solid grey";
        popup.style.pointerEvents = "none";
        popup.style.touchAction = "none";
        // popup.innerHTML = require('@/img/common/check.png'); 
        // "<img src=\"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Check_mark.svg/220px-Check_mark.svg.png\" />";
        document.body.append(popup);
        setTimeout(() => {
            popup.remove();
        }, 400);
    }

}