<template>
    <div class="form-group">
        <label>Path</label>
        <input type="text" class="form-control" v-model="localValue.path" />
    </div>
    <div class="form-group">
        <label>Name</label>
        <input type="text" class="form-control" v-model="localValue.name" />
    </div>
    <div class="form-group">
        <label>User</label>
        <input type="text" class="form-control" v-model="localValue.user" />
    </div>
    <div class="form-group">
        <label>Password</label>
        <input type="text" class="form-control" v-model="localValue.password" />
    </div>
    <div class="text-end">
        <button class="btn btn-primary" @click="testDb">
            Testa connessione
        </button>
    </div>
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as CONST from '@/const';
import WithModel from '@/mixins/withModel';
import { AppConfigClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';

@Options({})
export default class databaseConfig extends mixins(WithModel) {
    
    declare localValue: OM.DatabaseConfig;

    testDb(){
        AppConfigClient.testDbConnection()
        .then(x => {
            ModalServices.alertModal("OK", "Connessione riuscita!");
        })
        .catch(err => {
            ModalServices.alertModal("DONT", err);
        })
    }

}
</script>
