import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/img/common/chevron_up.svg'
import _imports_1 from '@/img/common/chevron_down.svg'


const _hoisted_1 = {
  class: "accordionButton",
  key: "up"
}
const _hoisted_2 = {
  class: "accordionButton",
  key: "down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["accordion", _ctx.openThis ? 'open' : ''])
    }, [
      _createElementVNode("div", {
        class: "accordionTitle",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openThis = !_ctx.openThis))
      }, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true),
        (_ctx.openThis)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
              _createElementVNode("button", { type: "button" }, [
                _createElementVNode("img", { src: _imports_0 })
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (!_ctx.openThis)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
              _createElementVNode("button", { type: "button" }, [
                _createElementVNode("img", { src: _imports_1 })
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      (_ctx.openThis)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["accordionBody", {'indent': _ctx.indent}])
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}