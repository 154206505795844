<template>
    <tree class="mt-2 bg-body" :nodes="nodes" :config="config">
        <template #after-input="props">
            <button v-if="!props.node.meta.isDirectory" class="btn btn-success p-2 btn-round ms-2" :class="{ 'btn-hollow': localValue != props.node.meta.fullPath }" @click="selectNode(props.node)">
            </button>
        </template>
    </tree>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Tree from "vue3-treeview";

@Options({
    components: {
        Tree
    }
})
export default class fileTreePathSelector extends mixins(WithModel) {
    
    @Prop() nodes;
    
    config = { roots: [] }
    
    declare localValue: string;
    
    selectNode(node){
        this.localValue = node.meta.fullPath;
        this.emitModel(this.localValue);
    }
    
    @Watch('nodes')
    @Watch('localValue')
    update(){
        
        const allChildren = new Set();
        // Raccogli tutti i nodi figli
        Object.values(this.nodes).forEach((node: any) => {
            node.children?.forEach(child => {
                allChildren.add(child);
            });
        });
        
        // Le root sono i nodi che non sono presenti come figli
        let roots = Object.keys(this.nodes).filter(nodeKey => !allChildren.has(nodeKey));
        this.config.roots = roots;
        
        this.openNodeContainingFile(this.nodes, this.localValue);
    }
    
    openNodeContainingFile(nodes, selectedFilePath) {
        let selectedNodeKey = null;
        
        // Trova il nodo con il percorso completo che corrisponde al file selezionato
        Object.keys(nodes).forEach(nodeKey => {
            if (nodes[nodeKey].meta?.fullPath === selectedFilePath) {
                selectedNodeKey = nodeKey;
            }
        });
        
        if (selectedNodeKey) {
            // Apri il nodo e i suoi genitori
            this.openParentNodes(nodes, selectedNodeKey);
        }
    }
    
    openParentNodes(nodes, nodeKey) {
        if (nodes[nodeKey]) {
            // Imposta lo stato del nodo a "opened: true"
            nodes[nodeKey].state = { ...nodes[nodeKey].state, opened: true };
            
            // Trova il genitore del nodo corrente
            const parentKey = this.findParentNode(nodes, nodeKey);
            if (parentKey) {
                this.openParentNodes(nodes, parentKey); // Ricorsione per aprire il genitore
            }
        }
    }
    
    findParentNode(nodes, childKey) {
        return Object.keys(nodes).find(nodeKey => nodes[nodeKey].children?.includes(childKey));
    }
    
    
    
}

</script>

<style scoped>

</style>
