import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';


let _routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'login',
		component: () => import('@/views/login.vue'),
	},
	{
		path: '/manage',
		name: 'manage',
		component: () => import('@/views/manage.vue'),
	}
];


let _router = createRouter({
	history: createWebHashHistory(),
	routes: _routes,
	scrollBehavior: (to, from, savedPosition) => {
		window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
	}
});

_router.beforeEach((to, from, next) => {
	if(store.state.beforeNavCbs.length == 0){
		next();
	} else {
		store.state.beforeNavCbs.forEach(x => {
			x(to, from);
		});
		store.state.beforeNavCbs = [];
		next();
	}
})

_router.afterEach((to, from) => {
	store.state.afterNavCbs.forEach(x => {
		x(to, from);
	});
	store.state.afterNavCbs = [];
})

export default _router;