import 'bootstrap/dist/css/bootstrap.css';
import "vue3-treeview/dist/style.css";
import './css/style.css';
import './filters';


import router from './router'
import store from './store'
import modalPlugin from './plugins/modalPlugin';
import FILTERS from '@/filters';

import App from '@/App.vue';
import { CommonServices } from '@/services/CommonServices'
import { createApp } from 'vue';
import loadComponents from './componentsLoader';
import ModalsContainer from './plugins/modalPlugin/opModalContainer.vue';
import { ModalServices } from './services/ModalServices';

CommonServices.showSpinnerCb = () => {
    store.state.showSpinner++;
}
CommonServices.hideSpinnerCb = () => {
    store.state.showSpinner--;
    if(store.state.showSpinner < 0){
        store.state.showSpinner = 0;
    }
}
CommonServices.unauthorizedCb = () => {
    if(router.currentRoute.value.path != '/')
        router.push('/?redirect=' + router.currentRoute.value.fullPath);
}
CommonServices.validationErrorsCb = (errors) => {
    ModalServices.validationErrorModal(errors);
}

router.afterEach((to, from) => {
    store.state.pageTitle = <string>to.meta.title;
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin);

app.config.globalProperties.$filters = FILTERS;

loadComponents(app);

app.component('opModalContainer', ModalsContainer)

app.mount('#app');