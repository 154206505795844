import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree = _resolveComponent("tree")!

  return (_openBlock(), _createBlock(_component_tree, {
    class: "mt-2 bg-body",
    nodes: _ctx.nodes,
    config: _ctx.config
  }, {
    "after-input": _withCtx((props) => [
      (!props.node.meta.isDirectory)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(["btn btn-success p-2 btn-round ms-2", { 'btn-hollow': _ctx.localValue != props.node.meta.fullPath }]),
            onClick: ($event: any) => (_ctx.selectNode(props.node))
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["nodes", "config"]))
}