import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-control" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("label", null, "Nome colonna", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", null, "DataType", -1)),
      _createElementVNode("select", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataTypes, (item) => {
          return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item), 9, _hoisted_4))
        }), 256))
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.dataType) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.dataType]
      ])
    ])
  ], 64))
}