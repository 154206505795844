<template>
    <div class="form-group">
        <label>Nome colonna</label>
        <input type="text" class="form-control" v-model="localValue.name" />
    </div>
    <div class="form-group">
        <label>DataType</label>
        <select class="form-control">
            <option v-for="item in dataTypes" :value="item">{{item}}</option>
        </select>
        <input type="text" class="form-control" v-model="localValue.dataType" />
    </div>
    <!-- <div class="form-group">
        <label>Length</label>
        <input type="number" class="form-control" v-model="localValue.length" />
    </div> -->
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as CONST from '@/const';
import WithModel from '@/mixins/withModel';

@Options({})
export default class dataTypeDefinition extends mixins(WithModel) {
    
    declare localValue: OM.DataTypeDefinition;

    dataTypes = CONST.DataTypes.GetAll();


}
</script>
