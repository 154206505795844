import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { ref: "rootElement" }
const _hoisted_2 = {
  key: 0,
  class: "opPaginationContainer top"
}
const _hoisted_3 = { class: "opSvuotaFiltri" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "opItemsTotal" }
const _hoisted_6 = { class: "opItemsTotalText" }
const _hoisted_7 = {
  class: "mb-4",
  style: {"width":"100%","overflow-x":"auto"}
}
const _hoisted_8 = {
  class: "opTable",
  ref: "tableRoot"
}
const _hoisted_9 = {
  key: 1,
  class: "opPaginationContainer bottom"
}
const _hoisted_10 = { class: "opSvuotaFiltri" }
const _hoisted_11 = { class: "opPaginationNumberContainer" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "opItemsTotal" }
const _hoisted_14 = { class: "opItemsTotalText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showUpperPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-link",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClearFilters && _ctx.onClearFilters(...args)))
            }, " Svuota filtri ")
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "opPaginationNumber",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.subtractPage && _ctx.subtractPage(...args)))
            }, " < "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["opPaginationNumber", {selected: _ctx.selectedPage == i}]),
                key: i,
                onClick: ($event: any) => (_ctx.setSelectedPage(i))
              }, _toDisplayString(i), 11, _hoisted_4))
            }), 128)),
            _createElementVNode("div", {
              class: "opPaginationNumber",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addPage && _ctx.addPage(...args)))
            }, " > "),
            _withDirectives(_createElementVNode("select", {
              class: "opItemsPerPage",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.xItemsPerPage) = $event))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("option", { value: 10 }, "10", -1),
              _createElementVNode("option", { value: 25 }, "25", -1),
              _createElementVNode("option", { value: 50 }, "50", -1),
              _createElementVNode("option", { value: 100 }, "100", -1)
            ]), 512), [
              [_vModelSelect, _ctx.xItemsPerPage]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[9] || (_cache[9] = _createTextVNode("Totale: ")),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.totalItemsCount), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("table", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default", { items: _ctx.items })
      ], 512)
    ]),
    (_ctx.showLowerPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-link",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClearFilters && _ctx.onClearFilters(...args)))
            }, " Svuota filtri ")
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "opPaginationNumber",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.subtractPage && _ctx.subtractPage(...args)))
            }, " < "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["opPaginationNumber", {selected: _ctx.selectedPage == i}]),
                key: i,
                onClick: ($event: any) => (_ctx.setSelectedPage(i))
              }, _toDisplayString(i), 11, _hoisted_12))
            }), 128)),
            _createElementVNode("div", {
              class: "opPaginationNumber",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addPage && _ctx.addPage(...args)))
            }, " > "),
            _withDirectives(_createElementVNode("select", {
              class: "opItemsPerPage",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.xItemsPerPage) = $event))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("option", { value: 10 }, "10", -1),
              _createElementVNode("option", { value: 25 }, "25", -1),
              _createElementVNode("option", { value: 50 }, "50", -1),
              _createElementVNode("option", { value: 100 }, "100", -1)
            ]), 512), [
              [_vModelSelect, _ctx.xItemsPerPage]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[11] || (_cache[11] = _createTextVNode("Totale: ")),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.totalItemsCount), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}