<template>
    <div class="form-group">
        <label><b>Prefisso delle tabelle in scrittura</b></label>
        <input type="text" class="form-control" v-model="localValue.tablePrefix" />
    </div>
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import * as CONST from '@/const';
import WithModel from '@/mixins/withModel';
import { AppConfigClient } from '@/services/Services';

@Options({})
export default class sqlFileImportConfig extends mixins(WithModel) {
    
    declare localValue: OM.SqlFileImportConfig;
    
}
</script>
