import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("label", null, "Path", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.path) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.path]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("label", null, "Name", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[7] || (_cache[7] = _createElementVNode("label", null, "User", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.user) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.user]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[8] || (_cache[8] = _createElementVNode("label", null, "Password", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.password) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.password]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.testDb && _ctx.testDb(...args)))
      }, " Testa connessione ")
    ])
  ], 64))
}